html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6c757d;
    font-family: 'Poppins';
    background-color: #fff;
    overflow-x: hidden;
    letter-spacing: 1px;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
ul {
    margin: 0px;
    padding: 0px;
}
p{
    padding: 0px;
    margin: 0;
}
svg {
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
ul {
    list-style: disc !important;
    padding-left: 18px;
}
ul li {
    margin-bottom: 20px;
}
#root {
    width: 100%;
    height: 100%;
}
.icons {
    width: 24px;
    height: 24px;
    display: block;
}
.space_div {
    flex-grow: 1;
}
.div_opt_alt{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.div_color{
    width: 24px;
    height: 36px;
    border: 2px solid #05161A;
    border-radius: 6px;
}


/* scrollbar */
::-webkit-scrollbar {
    height: 10px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px rgba(7, 196, 214, 0.5);
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px rgb(0 187 209);
    border-radius: 6px;
}
/* end */


/* table */
table{
    padding-bottom: 10px;
    width: 100%;
}
table th{
    font-family: "Poppins";
    font-weight: 700;
    font-size: 12px;
    color: #2D3C53;
    background-color: #E2E8F0;
    padding: 16px;
}
table tr{
    background-color: #ffffff;
}
table tr:nth-child(2n){
    background-color: #F6FAFF;
}
table td{
    padding: 16px;
    font-size: 12px;
}
table th:nth-child(-n + 1){
    border-top-left-radius: 8px;
}
table th:nth-last-child(-n + 1){
    border-top-right-radius: 8px;
}
table tr:nth-last-child(-n + 1) td:nth-child(-n + 1){
    border-bottom-left-radius: 8px;
}
table tr:nth-last-child(-n + 1) td:nth-last-child(-n + 1){
    border-bottom-right-radius: 8px;
}
/* end */


/* box text */
.jodit-react-container{
    width: -webkit-fill-available;
    text-align: left;
}
.jodit-wysiwyg p{
    padding: 0 !important;
    margin: 0 !important;
    font-family: "inter";
}
.jodit-status-bar{
    display: none;
}
.jodit-add-new-line, .jodit-add-new-line_after, .jodit-dialog__header-toolbar{
    display: none !important;
}
.jodit-dialog__panel{
    width: 380px !important;
}
.jodit-ui-button_keep, .jodit-ui-button_insert_as_text, .jodit-ui-button_text-icons_true, .jodit-status-bar{
    display: none !important;
}
.jodit-toolbar__box{
    background-color: #EEF1F5 !important;
    border-bottom: none !important;
}
.jodit-container:not(.jodit_inline){
    border-color: #EEF1F5 !important;
}
.jodit-ui-button_insert_only_text{
    background-color: #7c9f06 !important;
    color: #FFFFFF !important;
    display: block !important;
}
.jodit-ui-button_insert_only_text > span{
    display: none !important;
}
.jodit-ui-button_insert_only_text::after{
    content: "Copiar somente o texto!";
}
.jodit-dialog__content{
    display: none !important;
}
.jodit-dialog_footer_true .jodit-dialog__footer{
    align-items: center;
    justify-content: center;
}
.jodit-dialog_prompt{
    width: -webkit-fill-available;
    text-align: center;
    max-width: 100% !important;
    height: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.jodit-dialog_prompt > label{
    display: none !important;
}
.jodit-wysiwyg p img{
    vertical-align: middle;
}
.type_free{
    background-color: #9eb7d3;
    padding: 6px 10px;
    width: 60px;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
}
.type_free_active{
    background-color: #324d6b;
}
/* end */


/* Select seatch */
.css-13cymwt-control{
    border: 2px solid #ebebeb !important;
    font-family: 'Inter' !important;
    padding: 2px;
}
.css-1nmdiq5-menu{
    z-index: 999 !important;
}
.css-1fdsijx-ValueContainer{
    padding: 0px 8px;
}
.css-1hb7zxy-IndicatorsContainer{
    max-height: 32px!important;
}
.css-1xc3v61-indicatorContainer{
    padding: 0px;
}
/* end */


/* pop up */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 50%);
    font-family: 'Inter';
    transition: 0.5s;
}
.PopUp .all {
    width: 800px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: hidden;
    position: relative;
    border-radius: 6px;
    z-index: 30;
    opacity: 0;
    transition: 0.5s;
    animation: popup_all 0.5s linear;
    animation-fill-mode: forwards;
}
@keyframes popup_all {
    100%{
        opacity: 1;
    }
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
.PopUp .div_data {
    display: flex;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
}
.PopUp .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    padding-bottom: 10px;
    border-bottom: 2px solid #05161A;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.PopUp .close {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 2px solid #05161A;
    height: fit-content;
}
.PopUp .content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.PopUp .content .title_stage{
    font-size: 16px;
    color: #05161A;
}
.PopUp .content .div_alt_status{
    color: #05161A;
    cursor: pointer;
}
.PopUp .div_contents {
    background-color: #ffffff;
    padding: 16px;
}
.PopUp .button {
    padding: 0px 20px 20px;
}
.PopUp .register_data {
    height: 40px;
    background-color: #05161A;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    width: 100%;
}
.PopUp .register_data button{
    cursor: pointer;
    width: -webkit-fill-available;
    height: 40px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
}
.PopUp .register_data:hover{
    background-color: #0B6F60;
}
.PopUp .opt_select{
    cursor: pointer;
    width: 120px;
    color: #ffffff;
}
.PopUp .yes_update {
    background-color: #76c57e;
    padding: 6px 10px;
    border-radius: 8px;
}
.PopUp .not_update {
    background-color: #e95656;
    padding: 6px 10px;
    border-radius: 8px;
}
/* end */


/* loading */
.return_data_save{
    position: absolute;
    left: 0;
    top: 0;
    background: #b3b3b36e;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.return_data_save .newtons-cradle {
    --uib-size: 70px;
    --uib-speed: 1.2s;
    --uib-color: #05161A;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--uib-size);
    height: var(--uib-size);
}
.return_data_save .newtons-cradle__dot {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 25%;
    transform-origin: center top;
}
.return_data_save .newtons-cradle__dot::after {
    content: '';
    display: block;
    width: 100%;
    height: 25%;
    border-radius: 50%;
    background-color: var(--uib-color);
}
.return_data_save .newtons-cradle__dot:first-child {
    animation: swing var(--uib-speed) linear infinite;
}
.return_data_save .newtons-cradle__dot:last-child {
    animation: swing2 var(--uib-speed) linear infinite;
}
@keyframes swing {
    0% {
        transform: rotate(0deg);
        animation-timing-function: ease-out;
    }
    25% {
        transform: rotate(70deg);
        animation-timing-function: ease-in;
    }
    50% {
        transform: rotate(0deg);
        animation-timing-function: linear;
    }
}
@keyframes swing2 {
    0% {
        transform: rotate(0deg);
        animation-timing-function: linear;
    }
    50% {
        transform: rotate(0deg);
        animation-timing-function: ease-out;
    }
    75% {
        transform: rotate(-70deg);
        animation-timing-function: ease-in;
    }
}
/* end */


/* switch */
.switch{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
}
.switch .switch_wrapper{
    display: inline-block;
    width: 40px;
    height: 14px;
    position: relative;
}
.switch .switch_wrapper .switch_button{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    background-color: #9d9d9d;
}
.switch .switch_wrapper input{
    opacity: 0;
    width:0px;
    height:0px
}
.switch .switch_wrapper .switch_button::before{
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    left: -1px;
    top: -4px;
    bottom: 3px;
    border-radius: 50%;
    transition: .4s all ease;
    background-color: #FFFFFF;
    box-shadow:
        rgb(0 0 0 / 20%) 0px 2px 1px -1px,
        rgb(0 0 0 / 14%) 0px 1px 1px 0px,
        rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.switch .switch_wrapper .switch_button:hover:before{
    box-shadow:
        rgb(38 38 38 / 20%) 0px 0px 0px 0px,
        rgb(38 38 38 / 14%) 0px 0px 6px 8px,
        rgb(38 38 38 / 12%) 0px 1px 3px 0px;
}
.switch .switch_wrapper input:checked + .switch_button{
    background-color: #9ae3ac;
}
.switch .switch_wrapper input:checked + .switch_button::before{
    transform:translateX(20px);
    background-color: #34c759;
}
.switch .switch_wrapper input:checked +.switch_button:hover:before {
    box-shadow:
        rgb(79 70 229 / 20%) 0px 0px 0px 0px,
        rgb(79 70 229 / 14%) 0px 0px 6px 8px,
        rgb(79 70 229 / 12%) 0px 1px 3px 0px
}
/* end */


/* input */
input, select, textarea{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.div_input {
    position: relative;
    margin-top: 10px;
}
.name_input{
    position: absolute;
    left: 10px;
    padding: 0px 4px;
    background-color: #ffffff;
    top: -10px;
    font-style: italic;
}
.show_title {
    margin-bottom: 4px;
}
/* end */


/* list pages */
.list_pages{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Inter';
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
}
.list_pages .numb_page{
    background-color: #111827;
    padding: 2px 12px;
    border-radius: 4px;
    cursor: pointer;
}
.list_pages .numb_page:hover{
    background-color: #4a566e;
}
.list_pages .page_active {
    background-color: #a9b9cb;
}
/* end */


/* Search */
.new_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E2E8F0;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 40px;
}
.new_block .div_return {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}
.new_block .input_search {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}
.new_block .input_search .icons_search{
    position: absolute;
    top: 11px;
    left: 16px;
    height: 20px;
    width: 20px;
    transition: 0.5s;
}
.new_block .input_search .search{
    background-color: #CDD1D6;
    border: 2px solid #CDD1D6;
    padding: 10px 10px 10px 44px;
    width: 174px;
    color: #061821;
}
.new_block .title_page {
    font-size: 26px;
    font-weight: 500;
    font-family: 'bebas neue';
    line-height: normal;
}
.new_block .new_block_text {
    background-color: #5D9200;
    color: #FFFFFF;
    cursor: pointer;
    padding: 10px 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
    font-size: 12px;
    border: transparent;
    border-radius: 8px;
}
.new_block .new_block_text:hover {
    background-color: #ACC089;
}
.new_block .new_block_text .icons{
    width: 20px;
    height: 20px;
}
/* end */


/* show data page */
.show_page_data {
    width: -webkit-fill-available;
    padding: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
}
.show_page_data:nth-child(1n){
    margin-top: 20px;
}
.show_page_data .list_type_data{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 10px;
    margin-bottom: 16px;
}
.show_page_data .type_title{
    font-size: 16px;
    font-weight: 600;
    display: flex;
    gap: 10px;
    align-items: center;
}
.show_page_data .new_data{
    color: #5D9200;
    font-weight: 600;
    cursor: pointer;
}
.show_page_data .show_data_text .div_show_list{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
}
.show_page_data .show_data_text .div_show_list .div_list_data{
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.show_page_data .list_input_data{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}
.show_page_data .list_input_data:nth-child(1n){
    margin-top: 20px;
}
.show_page_data .list_input_data:nth-last-child(-n + 1){
    margin-bottom: 10px;
}
.show_page_data .list_input_data .div_input {
    position: relative;
    margin-top: 10px;
}
.show_page_data .list_input_data .div_add_img{
    display: flex !important;
    align-items: center !important;
    gap: 3px !important;
}
.show_page_data .list_input_data .div_add_img .delete_img_add{
    position: relative;
    margin-left: -10px;
    background-color: #05161A;
    padding: 7px;
    color: #FFFFFF;
    cursor: pointer;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.show_page_data .list_input_data .div_add_img .new_file_add{
    background-color: #c9b174;
    padding: 10px;
    color: #FFFFFF;
    border-radius: 6px;
    cursor: pointer;
    min-width: 150px;
    text-align: center;
    font-size: 12px;
}
.show_page_data .list_input_data .div_add_img .new_file_add:hover, .show_page_data .list_input_data .div_add_img .new_file_add_active{
    background-color: #996e00;
}
.show_page_data .list_input_data .div_add_img .new_file_add label{
    cursor: pointer;
}
.show_page_data .list_input_data .div_add_img .new_file_add input[type="file"]{
    display: none;
}
.show_page_data .show_data_text{
    background-color: #fff;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    padding: 10px;
    gap: 0;
}
.show_page_data .show_data_text .div_add_new_data{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E2E8F0;
    padding: 4px 16px 2px 16px;
    margin-bottom: 16px;
}
/* end */