.Content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    transition: 1s;
}
.Content .show_site{
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 888;
    background-color: #05161A;
    padding: 10px;
    border-radius: 50%;
}
.Content .icons_site{
    display: flex;
    width: 20px;
    height: 20px;
}
.Content .list_menu {
    width: 244px;
    max-width: 244px;
    min-width: 244px;
    height: 100%;
    background-color: #05161A;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.2s;
}
.Content .active_menu{
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    transform: translateX(0px) translateY(0px);
}
.Content .name {
    font-size: 18px;
    color: #fff;
}
.Content .button_edit {
    color: #ffffff;
    background-color: #7c9f06;
    padding: 6px 16px;
    cursor: pointer;
}
.Content .opt_menu_dash {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 4px;
    transition: 1s;
}
.Content .name_menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: -webkit-fill-available;
    font-size: 13px;
    gap: 10px;
}
.name_menu_{
    justify-content: center;
}
.Content .name_submenu{
    display: none;
    flex-direction:column;
    width: -webkit-fill-available;
    padding-left: 30px;
    align-items: flex-start;
}
.Content .submenu_active{
    display: flex;
}
.Content .icons_active{
    transform: rotate(90deg);
}
.Content .icons_submenu{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}
.Content .space_submenu{
    padding-left: 10px;
}
.Content .show_menu_dash {
    padding: 16px 0px 16px 10px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    width: -webkit-fill-available;
}
.Content .show_menu_dash:hover{
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.2s;
}
.Content .sub_menu_show_dash {
    font-size: 12px;
}
.Content .active_dash, .Content .active_dash:hover{
    background-color: rgba(7, 196, 214, 0.5);
    border-right: 4px solid rgba(255, 196, 0, 1);
    transform: none;
}
.Content .show_menu_dash_{
    padding: 10px;
}
.Content .div_icons{
    display: flex;
}
.Content .icons_dash{
    width: 24px;
    height: 24px;
    display: block;
}
.Content .list_data {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    background-color: #eef0f5;
}
.Content .show_data{
    width: -webkit-fill-available;
    margin: 20px 20px 60px;
    border-radius: 6px;
}
.Content .no_data{
    text-align: center;
    font-size: 12px;
    width: -webkit-fill-available;
    margin-top: 20px;
}
.Content .list_opt_add{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}
.Content .list_opt_add_{
    flex-direction: column;
}
.Content .show_option {
    align-items: center;
    background-color: #6a85ae;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    height: 106px;
    justify-content: center;
    width: -webkit-fill-available;
}
.Content .opt_add_project{
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 10px;
}
.Content .opt_add_project_{
    padding: 10px;
}

.Content .show_opt_menu{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.Content .div_logo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #E2E8F0;
    height: 90px;
    cursor: pointer;
}
.Content .div_logo_opt{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    padding: 10px 20px;
    transition: 1s;
}
.Content .div_logo_opt_{
    padding: 10px;
    justify-content: center;
}
.Content .logotipo{
    width: auto;
    height: 60px;
    display: block;
    transition: 1s;
}
.Content .logo_{
    width: 30px;
    height: auto;
}


/* effect menu */
.Content .div_profile{
    height: 80px;
}
.Content .div_profile_data{
    cursor: pointer;
    background-color: rgba(106, 133, 174, 0.3);
    border-radius: 8px;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    width: calc(90% - 32px);
    margin: 0 auto;
}
.Content .div_profile_data_{
    padding: 0px;
    justify-content: center;
    width: 70%;
}
.Content .div_img{
    display: flex;
}
.Content .show_img_profile{
    width: 32px;
    height: 32px;
    border-radius: 32px;
}
.Content .name_user{
    color: #ffffff;
    font-family: 'inter';
    font-weight: bold;
    text-align: center;
}
.Content .div_logo .burger {
    position: relative;
    width: 40px;
    height: 24px;
    background: transparent;
    cursor: pointer;
    display: block;
}
.Content .div_logo .burger input {
    display: none;
}
.Content .div_logo .burger span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #E2E8F0;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}
.Content .div_logo .burger span:nth-of-type(1) {
    top: 0px;
    transform-origin: left center;
}
.Content .div_logo .burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
}
.Content .div_logo .burger span:nth-of-type(3) {
    top: 100%;
    transform-origin: left center;
    transform: translateY(-100%);
}
.Content .div_logo .burger input:checked~span:nth-of-type(1) {
    transform: rotate(45deg);
    top: 0px;
    left: 5px;
}
.Content .div_logo .burger input:checked~span:nth-of-type(2) {
    width: 0%;
    opacity: 0;
}
.Content .div_logo .burger input:checked~span:nth-of-type(3) {
    transform: rotate(-45deg);
    top: 28px;
    left: 5px;
}
/* end */


.Content .div_click_menu_block{
    display: block;
}
.Content .div_click_menu_none{
    display: none;
}
.Content .menu_close_hide{
    display: none;
}
.Content .profile_open{
    display: block;
}
.Content .profile_close{
    display: block;
}

@media only screen and (max-width: 850px) {
    .Content .list_menu{
        width: 60px;
        max-width: 60px;
        min-width: 60px;
        transform: translateX(0px) translateY(0px);
    }
    .Content .logotipo{
        width: 30px;
        height: auto;
    }
    .Content .div_logo .burger{
        display: none;
    }
    .Content .div_logo_opt{
        padding: 10px;
        justify-content: center;
    }
    .Content .icon_menu_hamburguer{
        display: none;
    }
    .Content .div_profile_data{
        padding: 0px;
        justify-content: center;
    }
    .Content .name_user, .Content .open_profile, .Content .name_menu_dash{
        display: none;
    }
    .Content .show_menu_dash{
        padding: 10px;
    }
    .Content .name_menu{
        justify-content: center
    }
}
@media only screen and (max-width: 560px) {
    .Content{
        flex-direction: column;
    }
    .Content .div_click_menu_none{
        display: block;
    }
    .Content .profile_close{
        display: none;
    }
    .Content .menu_open{
        animation: menu_open 0.5s linear;
        animation-fill-mode: forwards;
    }
    @keyframes menu_open {
        0%{
            height: 0;
        }
        100%{
            height: calc(100vh - 146px);
        }
    }
    .Content .menu_close{
        height: 0;
        animation: menu_close 0.5s linear;
        animation-fill-mode: forwards;
    }
    @keyframes menu_close {
        0%{
            height: calc(100vh - 146px);
        }
        100%{
            height: 0;
        }
    }
    .Content .div_logotipo{
        cursor: pointer;
        pointer-events: none;
    }
    .Content .div_logo .burger{
        display: block;
    }
    .Content .div_logo_opt{
        justify-content: space-between;
        padding: 10px 20px;
        transition: 1s;
    }
    .Content .show_opt_menu{
        height: auto;
    }
    .Content .div_profile_data{
        width: calc(90% - 32px);
        margin: 0 auto;
        padding: 0 16px;
        justify-content: space-between;
    }
    .Content .list_menu{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: auto;
        z-index: 2;
        transform: translateX(0px) translateY(0px);
        position: absolute;
    }
    .Content .list_data{
        margin-top: 66px;
    }
    .Content .div_logo{
        margin-bottom: 0px;
        height: 66px;
    }
    .Content .show_menu_dash{
        padding: 20px;
    }
    .Content .name_menu{
        justify-content: flex-start;
        font-size: 16px;
    }
    .Content .icon_menu_hamburguer{
        display: block !important;
    }
    .Content .name_user, .Content .open_profile, .Content .name_menu_dash{
        display: block;
    }
    .Content .menu_close_hide{
        display: block;
        font-size: 16px;
    }
}