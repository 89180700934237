.Page_Unit_Details .type{
    width: 180px;
}
.Page_Unit_Details .area {
    width: 120px;
    text-align: center;
}
.Page_Unit_Details .plate{
    width: 100px;
    text-align: center;
}
.Page_Unit_Details .email, .Page_Unit_Details .brand{
    width: 300px;
}
.Page_Unit_Details .cep, .Page_Unit_Details .number{
    width: 80px;
    text-align: center;
}