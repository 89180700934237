.Page_Condominium_Details .cep, .Page_Condominium_Details .number{
    width: 80px;
    text-align: center;
}
.Page_Condominium_Details .uf{
    width: 30px;
    text-align: center;
}
.Page_Condominium_Details .burgh{
    width: 300px;
}
.Page_Condominium_Details .type_edification{
    width: 250px;
}
.Page_Condominium_Details .block_edification{
    width: 390px;
}