.recuver_pass_center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.PopUp .recuver_pass{
    width: 500px;
}
.PopUp .recuver_pass .button_profile{
    width: -webkit-fill-available;
}
.PopUp .recuver_pass .align_profile{
    text-align: left;
}
.PopUp .recuver_pass .span_name_textarea{
    position: absolute;
    left: 8px;
    top: -3px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;
    padding: 0 10px;
}
.PopUp .recuver_pass .pass{
    width: 120px;
}
.PopUp .recuver_pass .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .recuver_pass .div_input{
    width: auto;
}


@media only screen and (max-width: 570px) {
    .PopUp .recuver_pass {
        width: 90%;
    }
}